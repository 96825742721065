import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import "./style.css";
import { useCache } from "../../hooks/useCache";
import { Load } from "../Load";
import { ItemCarrinho } from "../ItemCarrinho";
import { AddMesaPedidoDto, AddPedidoProps, MesasProps } from "../../global/types";
import { currencyFormatter } from "../../utils/currencyFormatter";
// import { api } from "../../services/api";
import { ModalNome } from "../ModalNome";
import { VerificaCaixaAberto } from "../../utils/loadCache";
import { getApiClient } from "../../utils/getApiClient";

type ParamsProps = {
  closeModal: (limparCarrinho: boolean) => void;
  listComandas: MesasProps[];
  nrMesa: string;
}

type INavigateProps = {
  codCliente: string;
}

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 1120 ? 850 : window.innerWidth - 60,
    height: window.innerHeight - 150,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

export function CarrinhoMesa({ closeModal, listComandas, nrMesa }: ParamsProps) {
  const { pedidos, updatePedidos, empresa } = useCache();
  const { codCliente } = useParams() as INavigateProps;

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");
  const [totalCarrinho, setTotalCarrinho] = useState<number>(0);

  const [modalNome, setModalNome] = useState(false);

  const handleBtnMenosItem = (i: number) => {
    const tempList: AddPedidoProps[] = [];

    pedidos.forEach((rs, key) => {
      let excluir = false;
      let nQtd = rs.qtd / 1000;
      const valorUnit: number = rs.valor / nQtd;
      if (key === i) {
        if (nQtd === 1) {
          if (window.confirm("Deseja realmente excluir este item do carrinho?"))
            excluir = true;
        } else
          nQtd -= 1;
      }

      if (!excluir)
        tempList.push({ ...rs, qtd: nQtd * 1000, valor: nQtd * valorUnit });
    })

    updatePedidos(tempList);
  }

  const handleBtnMaisItem = (i: number) => {
    const tempList: AddPedidoProps[] = [];

    pedidos.forEach((rs, key) => {
      let nQtd = rs.qtd / 1000;
      const valorUnit: number = rs.valor / nQtd;
      if (key === i)
        nQtd += 1;

      tempList.push({ ...rs, qtd: nQtd * 1000, valor: nQtd * valorUnit });
    })

    updatePedidos(tempList);
  }

  const handleComanda = async (comanda: MesasProps) => {
    if (!window.confirm("Deseja realmente finalizar seu pedido?"))
      return;

    setLoading(true);

    setTxtLoading("Verificando status do Estabelecimento");
    const caixaAberto = await VerificaCaixaAberto(codCliente);

    if (!caixaAberto) {
      setLoading(false);
      setTxtLoading("");
      return alert("O Estabelecimento já encerrou seu expediente.");
    }

    setTxtLoading("Enviando pedido...");

    const newListPed:AddPedidoProps[] = [];
    pedidos.map(item => {
      newListPed.push({...item, valor: item.valor / (item.qtd / 1000)});
    })

    const request: AddMesaPedidoDto = {
      id_mesa: comanda.id,
      imprimir_fichas: 0,
      mesa: `${comanda.mesa}`,
      nome: comanda.nome,
      varejo: 0,
      pedidos: newListPed,
      in_web: 1
    };

    await getApiClient(codCliente).post(`${codCliente}/pedido`, { ...request })
      .then((result) => {
        if (!result.data)
          return;

        alert("Pedido enviado com sucesso!");
        closeModal(true);
      })
      .catch((err) => {

        if (err.response.data.msg)
          alert(err.response.data.msg);
        else
          alert("Erro ao enviar pedido. Tente novamente mais tarde.");

      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleNovaComanda = async (nome: string | undefined = undefined) => {
    if (!nome)
      setModalNome(true);
    else {
      setModalNome(false);
      setLoading(true);

      setTxtLoading("Verificando status do Estabelecimento");
      const caixaAberto = await VerificaCaixaAberto(codCliente);

      if (!caixaAberto) {
        setLoading(false);
        setTxtLoading("");
        return alert("O Estabelecimento já encerrou seu expediente.");
      }

      setTxtLoading("Enviando pedido...");

      const newListPed:AddPedidoProps[] = [];
      pedidos.map(item => {
        newListPed.push({...item, valor: item.valor / (item.qtd / 1000)});
      })

      // console.log(newListPed);
      // return;
      const request: AddMesaPedidoDto = {
        id_mesa: 0,
        imprimir_fichas: 0,
        mesa: nrMesa,
        nome: nome,
        varejo: 0,
        pedidos: newListPed,
        in_web: 1
      };

      await getApiClient(codCliente).post(`${codCliente}/pedido`, { ...request })
        .then((result) => {
          if (!result.data)
            return;

          alert("Pedido enviado com sucesso!");
          closeModal(true);
        })
        .catch(() => {
          alert("Erro ao enviar pedido. Tente novamente mais tarde.");
        })
        .finally(() => {
          setLoading(false);
          setTxtLoading("");
        });
    }
  }

  useEffect(() => {
    let t: number = 0;
    pedidos.forEach((rs) => {
      t += rs.valor / 100;
    });

    setTotalCarrinho(t);
  }, [pedidos]);

  return (
    <div id="ModalCarrinhoMesa">
      <div className="header">
        <h3>Carrinho</h3>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={() => closeModal(false)} />
      </div>

      <div style={{ height: window.innerHeight - 120, overflow: "auto" }}>
        {loading && (
          <Load txtLoading={txtLoading} />
        )}

        {!loading && (
          <>
            <div className="content">
              {pedidos.map((rs, i) => (
                <ItemCarrinho key={`cp-${i}`} data={rs} btnMais={(e) => handleBtnMaisItem(e)} btnMenos={(e) => handleBtnMenosItem(e)} keyIndex={i} />
              ))}
            </div>

            <div className="list-footer">
              <h4>Valor total dos Produtos: {currencyFormatter(totalCarrinho)}</h4>
            </div>

            {pedidos.length > 0 && nrMesa !== "0" && (
              <>
                <h5>ESCOLHA/CRIE SUA COMANDA</h5>
                <div className="contentComandas">
                  {listComandas?.map((item) => (
                    <div key={`${item.id}`} className="box" onClick={() => handleComanda(item)}>
                      <p>{item.mesa}</p>
                      <p>{item.nome}</p>
                    </div>
                  ))}

                  {empresa.nome_obrigatorio != 2 && (
                    <div className="box" onClick={() => handleNovaComanda()}>
                      <FontAwesomeIcon icon={faPlus} style={{ fontSize: "25px" }} />
                      <p>NOVA COMANDA</p>
                    </div>
                  )}

                </div>
              </>
            )}

          </>
        )}
      </div>

      <Modal isOpen={modalNome} style={{ content: { ...styleModal.content, width: 350, height: 220 }, overlay: { ...styleModal.overlay } }}>
        <ModalNome closeModal={() => setModalNome(false)} confirma={(e) => handleNovaComanda(e)} />
      </Modal>

    </div>
  )
}